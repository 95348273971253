import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Input from './Input';
import Select from './Select';
import Recaptcha from './Recaptcha';
import ContactMarkdown from './ContactMarkdown';
import { getStringData } from '../../lib/strapi';
import type { SectionContact } from '@eva/cms-types';
import ContactSuccessAnimation from './ContactSuccessAnimation';

interface Props {
  data: SectionContact['attributes'];
}

function ContactForm(props: Props) {
  const {
    errorMessage,
    sendButtonText,
    successMessage,
    namePlaceholder,
    textPlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    privacyPolicyText,
  } = getStringData(props.data);
  const [status, setStatus] = useState<'success' | 'error' | undefined>(
    undefined,
  );

  async function submit(e: SubmitEvent) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const response = await fetch('/api/contact', {
      method: 'POST',
      body: formData,
    });
    if (response.status === 200) {
      setStatus('success');
      (e.target as HTMLFormElement).reset();
      return;
    }
    setStatus('error');
  }

  useEffect(() => {
    if (status != 'success') {
      return;
    }

    const timeout = setTimeout(() => setStatus(undefined), 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  return (
    <>
      <CSSTransition
        in={status === 'success'}
        timeout={500}
        classNames="fade"
        unmountOnExit
        mountOnEnter
      >
        <div className="absolute w-full h-full bg-white flex justify-center items-center gap-4 z-10 flex-col rounded-[2rem] transition-all duration-500">
          <ContactSuccessAnimation />
          <p className="text-h4">{successMessage}</p>
        </div>
      </CSSTransition>
      <form
        className="col-span-6 desktop:col-span-4 grid desktop:grid-cols-4 gap-y-4 desktop:gap-y-6 desktop:gap-x-3 my-5"
        onSubmit={submit}
      >
        <Input type="text" name="name" required placeholder={namePlaceholder} />
        <Input
          type="email"
          name="email"
          required
          placeholder={emailPlaceholder}
        />
        <Input type="tel" name="phone" placeholder={phonePlaceholder} />
        <Select data={props.data} />
        <textarea
          className="col-span-4 bg-darkWhite p-4 rounded-md min-h-[7.4375rem]"
          name="message"
          required
          placeholder={textPlaceholder}
          minLength={4}
        ></textarea>
        <div className="col-span-4 desktop:col-span-3 mt-2">
          <ContactMarkdown text={privacyPolicyText} />

          {status === 'error' && (
            <p className="text-body1 text-red-500 mt-4">{errorMessage}</p>
          )}
        </div>
        <Recaptcha refresh={status} />
        <button className="col-span-4 desktop:col-span-1 bg-dark rounded-full text-white text-body1 h-fit py-5 mt-2 transition-all ease-in-out duration-500 scale-100 hover:scale-105">
          {sendButtonText}
        </button>
      </form>
    </>
  );
}

export default ContactForm;
