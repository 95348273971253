function Input(
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
) {
  return (
    <input
      className="col-span-4 desktop:col-span-2 bg-darkWhite p-4 rounded-md w-full"
      {...props}
    />
  );
}

export default Input;
