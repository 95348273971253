import { useCallback, useEffect, useState } from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
interface Props { 
  refresh?: string | number; 
}

const Recaptcha = (props: Props) => {
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setRefreshReCaptcha((r) => !r), 90_000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    setRefreshReCaptcha((r) => !r);
  }, [props.refresh]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.PUBLIC_RECAPTCHA_SITE_KEY}
    >
      <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
      <input type="text" hidden value={token} name="recaptchaToken" />
    </GoogleReCaptchaProvider>
  );
};

export default Recaptcha;
