import { getStringData } from '../../lib/strapi';
import type { FormSubject, SectionContact } from '@eva/cms-types';

interface Props {
  data: SectionContact['attributes'];
}

function ContactForm(props: Props) {
  const { subjectPlaceholder } = getStringData(props.data);
  const subjectOptions = props.data
    .subject as unknown as FormSubject['attributes'][];

  return (
    <div className="relative col-span-4 desktop:col-span-2">
      <select
        className="bg-darkWhite p-4 rounded-md appearance-none w-full h-full"
        name="subject"
        required
      >
        <option disabled selected value="">
          {subjectPlaceholder}
        </option>
        {subjectOptions.map((option) => {
          const { text } = getStringData(option);
          return (
            <option key={text} value={text}>
              {text}
            </option>
          );
        })}
      </select>
      <img
        src="/chevron-down.svg"
        alt=""
        className="absolute right-5 top-1/2 -translate-y-1/2"
      />
    </div>
  );
}

export default ContactForm;
