import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';

interface Props {
  text?: string;
}

function ContactMarkdown(props: Props) {
  return (
    <ReactMarkdown
      components={{
        p: (props) => <p className="text-body2">{props.children}</p>,
        a: (props) => (
          <a className="text-body2 underline" {...props}>
            {props.children}
          </a>
        ),
      }}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
      children={props.text}
    />
  );
}

export default ContactMarkdown;
